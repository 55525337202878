import React, { useMemo, useState } from "react"
import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official'
import { Nav, NavItem, NavLink, Spinner } from 'reactstrap'
import classnames from 'classnames'
import { connect } from "react-redux"
import { useTheme } from "../utils/useTheme";

const LineGraph = ({
  useGraphQuery,
  queryParams = [],
  defaultActiveTab = 1,
}) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const { data, isLoading, isRefetching, isSuccess } = useGraphQuery(
    ...queryParams
  );
  var darkTheme = true
  // check if light or dark mode
  if (useTheme() === "light-mode"){
    darkTheme = false
    console.log("darkTheme", darkTheme)
  }
  const activeTabData = useMemo(() => JSON.parse(JSON.stringify(data?.[activeTab] ?? [])), [data, activeTab]);

  const chartOptions = (graph, darkTheme) => {
    var backgroundColor = "#fff"
    var labelcolor = "#000"
    if (darkTheme === true) {
      backgroundColor = "#2B283B"
      labelcolor = "#fff"
    }
    return {
      credits: { enabled: false },
      chart: {
        type: "spline",
        backgroundColor: backgroundColor,  // <-- Add background color
      },
      title: {
        text: "",
      },
      yAxis: {
        title: {
          text: graph.data.yTitle,
          style: {
            color: labelcolor
          }
        },
        labels: {
          style: {
            color: labelcolor
         }
        }
      },
      xAxis: {
        categories: graph.data.labels,
        labels: {
          //rotation: 0,
          step: 0,
          style: {
            color: labelcolor
         }
        },
        title: {
          text: graph.data.xTitle,
          style: {
            color: labelcolor
          }
        },
      },
      series: graph.data.series,
    };
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const renderTab = (graph, index) => {
    return (
      <NavItem key={index}>
        <NavLink
          href="#"
          className={classnames({ active: activeTab === index })}
          onClick={() => toggleTab(index)}
        >
          {graph.source}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <>
      {(isLoading || isRefetching) && (
        <Spinner
          color="primary"
          size="sm"
          style={{ position: "absolute", right: 20 }}
        />
      )}
      {isSuccess && (
        <>
          <Nav pills className="navtab-bg my-4 center-pills">
            {Array.isArray(data) && data.map((graph, index) => renderTab(graph, index))}
          </Nav>
          {
            activeTabData && data?.[activeTab] && (
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions(activeTabData, darkTheme)}
              />
            )
          }
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  date: state.date,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(LineGraph);
